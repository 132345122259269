/* You can add global styles to this file, and also import other style files */
/* Layout */

@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "primeng/resources/primeng.min.css";
@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";

@import 'primeflex/primeflex.scss';

@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light.css';
@import 'tippy.js/animations/scale.css';

@import '@angular/cdk/overlay-prebuilt.css';

.content-container {
  max-width: 1500px;
  margin: 0 auto;
}


:root {
  --title-color: rgb(27, 45, 69);
}

html {
  height: 100%;
  min-height: 100%;
}

body {
  background: var(--surface-card);
  height: 100%;
  min-height: 100%;
}

.title {
  color: var(--title-color);
  font-weight: 800;
}

.subtitle {
  color: var(--text-color);
  @include styleclass('text-lg md:text-xl');
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.description {
  @include styleclass('text-base md:text-lg');
}

h1.title {
  // font-size: 2.5rem;
  @include styleclass('text-3xl md:text-6xl');
}

h2.title, h3.title, h4.title {
  // font-size: 2.5rem;
  @include styleclass('text-3xl md:text-5xl');
}


.main-content app-hero > div,
.main-content app-service-section > section,
.main-content app-feature-section > section,
.main-content app-icon-grid-section > section,
.main-content cs-the-team-section > section,
.main-content cs-main-cta-section > section,
.main-content > section
{
  @include styleclass('px-4 md:px-4 pt-4 md:pt-7 pb-6 md:pb-8');
}

markdown > h1 {
  @include styleclass('text-xl md:text-2xl text-color');
}

markdown > h2 {
  @include styleclass('text-lg md:text-xl text-color');
}

markdown > h3 {
  @include styleclass('text-lg md:text-lg text-color');
}
